<template>
  <div class="wrapper">
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select="select"
      :switchable="$route.query.select==1?true:false"
    />
  </div>
</template>

<script>
import { addressList } from '../../../api'
export default {
  components: {},
  props: {},
  data() {
    return {
      chosenAddressId: "",
      list: [],
    };
  },
  watch: {
  },
  computed: {},
  methods: {
    addressList() {
      addressList({}).then(res=>{
        var list = res.data.data
        var arr = []
        for (var item of list) {
          item.tel = item.phone
          item.isDefault = item.defaultStatus==1?true:false
          item.address = item.province + (item.province==item.city?'':item.city)+item.region+item.detailAddress
          arr.push(item)
        }
        this.list = arr
      })
    },
    onAdd() {
      this.$router.push({path: '/my/addressList/address',query: {Length: this.list.length}})
    },
    onEdit(item) {
      item.type = 'edit'
      
      this.$router.push({path: '/my/addressList/address',query: item})
    },
    select(item) {
        if (this.$route.query.select) {
          var orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
          orderInfo.address = item.address
          orderInfo.name = item.name
          orderInfo.phone = item.phone
          orderInfo.userAddressId = item.id
          localStorage.setItem('orderInfo', JSON.stringify(orderInfo))
          this.$router.go(-1)
        }
    }
  },
  created() {
    this.chosenAddressId = this.$route.query.id?Number(this.$route.query.id):''
    this.addressList()
    
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: RGBA(247, 247, 247, 1);
}
</style>